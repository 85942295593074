import Vue from "vue";

import { apiCall } from "@/lib/api";
import { indexArray, titleCase } from "@/lib/util";
import { materialColorsObject } from "@/lib/material-colors";
import { Crop } from "@/lib/types/Crop";
import { GrowthStage } from "@/lib/types/GrowthStage";
import { ActionMap, ActionTree, createMutations, GetterMap, GetterTree, Module, RootGetters } from "..";
import { Paginated } from "@/lib/types/Paginated";
import { CropYield } from "@/lib/types/CropYield";

function getCropColor(crop: Crop): string {
  const BIAS = 3;
  const name = crop.name.en;
  const hash = name.split('').reduce((acc, x) => acc + x.charCodeAt(0), 0) + BIAS;
  const colors = Object.keys(materialColorsObject);
  const mainColor = colors[hash % colors.length];
  const shades = Object.keys(materialColorsObject[mainColor]);
  const shade = shades[hash % shades.length];
  return materialColorsObject[mainColor][shade];
}

export interface CropsState {
  /* All crops by English name */
  allCrops: Record<string, Crop>;
  /* All crops by ID */
  allCropsIndex: Record<string, Crop>;
  cropsPerSeason: Record<string, Record<string, Crop>>,
  cropsPerSeasonIndex: Record<string, Record<string, Crop>>,
  noCropObject: Crop;
  growthStagesByCropId: Record<string, GrowthStage[]>;
  growthStageIndex: Record<string, GrowthStage>,
  selectedCrop: Crop | null,
  cropYieldsByCropIdAndSeason: Record<string, Record<string, CropYield>>;
}

function initialState(): CropsState {
  return {
    allCrops: {},
    allCropsIndex: {},
    cropsPerSeason: {},
    cropsPerSeasonIndex: {},
    noCropObject: {
      id: "null",
      cropIds: new Set(["null"]),
      system_links: [],
      name: {
        en: "Unspecified crop",
        ro: "Cultură nespecificată",
        fr: "Culture non spécifiée",
      },
      color: "#CAD1D2",
    },
    growthStagesByCropId: {},
    growthStageIndex: {},
    selectedCrop: null,
    cropYieldsByCropIdAndSeason: {},
  };
}

const mutations = createMutations({
  clearCrops(state: CropsState) {
    const s = initialState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
  /**
   * Set the available crops from an API response.
  */
  setCrops(state, { crops, seasonId }: { crops: Crop[], seasonId: string }) {
    const groupedCropsById = {};
    const groupedCropsByName = {};

    crops.forEach((crop) => {
      Object.keys(crop.name).forEach(
        (key) => (crop.name[key] = titleCase(crop.name[key]))
      );

      if (state.allCrops[crop.name.en]) {
        groupedCropsByName[crop.name.en] = state.allCrops[crop.name.en];
        groupedCropsByName[crop.name.en].cropIds.add(crop.id);
        groupedCropsById[crop.id] = groupedCropsByName[crop.name.en];
      } else if (groupedCropsByName[crop.name.en]) {
        groupedCropsByName[crop.name.en].cropIds.add(crop.id);
        groupedCropsById[crop.id] = groupedCropsByName[crop.name.en];
      } else {
        // Not a deep copy, but we should never change the
        // name of the crop in the frontend.
        const newCrop = {
          ...crop,
          cropIds: new Set([crop.id]),
          color: crop.color || getCropColor(crop),
        };
        groupedCropsByName[crop.name.en] = newCrop;
        groupedCropsById[crop.id] = newCrop;
      }
    });

    groupedCropsById["null"] = state.noCropObject;
    groupedCropsByName[""] = state.noCropObject;

    Vue.set(state.cropsPerSeason, seasonId, groupedCropsByName);
    Vue.set(state.cropsPerSeasonIndex, seasonId, groupedCropsById);
    Object.assign(state.allCrops, groupedCropsByName);
    Object.assign(state.allCropsIndex, groupedCropsById);
  },
  setCropSelected(state, cropId: string) {
    if (cropId) {
      state.selectedCrop = state.allCropsIndex[cropId];
    } else {
      state.selectedCrop = null;
    }
  },
  removeSeasonCrops(state, seasonId: string) {
    Vue.set(state.cropsPerSeason, seasonId, undefined);
    Vue.set(state.cropsPerSeasonIndex, seasonId, undefined);
  },
  setGrowthStagesByCropId(state, { cropId, growthStages }: { cropId: string, growthStages: GrowthStage[] }) {
    state.growthStagesByCropId = {
      ...state.growthStagesByCropId,
      [cropId]: growthStages,
    };
  },
  setGrowthStageIndex(state, growthStageIndex: Record<string, GrowthStage>) {
    state.growthStageIndex = { ...growthStageIndex };
  },
  setCropYieldsByCropIdAndSeason(state, { cropId, seasonId, cropYields }) {
    if (!state.cropYieldsByCropIdAndSeason[cropId]) {
      Vue.set(state.cropYieldsByCropIdAndSeason, cropId, {});
    }
    Vue.set(state.cropYieldsByCropIdAndSeason[cropId], seasonId, cropYields);
  },
});

export type CropsMutations = typeof mutations;


export interface CropsGetters extends GetterMap {
  crops: Record<string, Crop>;
  cropsForCurrentSeason: Crop[];
  sortedCropIds: string[];
  allCrops: CropsState["allCropsIndex"];
  allCropGroups: CropsState["allCrops"];
  cropsPerSeason: CropsState["cropsPerSeason"];
  totalCrops: number;
  selectedCrop: Crop;
  selectedCropId: string;
  growthStagesByCropId: CropsState["growthStagesByCropId"];
  growthStageIndex: CropsState["growthStageIndex"];
  cropYieldsByCropIdAndSeason: CropsState["cropYieldsByCropIdAndSeason"];
}

const getters: GetterTree<CropsState, CropsGetters> = {
  crops(state, getters, rootState, rootGetters) {
    if (!rootGetters.currentSeasonId) return {};
    return state.cropsPerSeasonIndex[rootGetters.currentSeasonId] || {};
  },
  cropsForCurrentSeason(state, getters, rootState, rootGetters) {
    if (!rootGetters.currentSeasonId) return [];
    return Object.values(state.cropsPerSeason[rootGetters.currentSeasonId] || {});
  },
  sortedCropIds(state, getters) {
    return getters.cropsForCurrentSeason
      .map((crop) => Array.from(crop.cropIds))
      .flat();
  },
  allCrops(state) {
    return state.allCropsIndex;
  },
  allCropGroups(state) {
    return state.allCrops;
  },
  cropsPerSeason(state) {
    return state.cropsPerSeason;
  },
  totalCrops(state, getters) {
    if (!getters.crops) return -1;
    return Object.keys(getters.crops).length;
  },
  selectedCrop(state) {
    return state.selectedCrop;
  },
  selectedCropId(state) {
    return state.selectedCrop?.id;
  },
  growthStagesByCropId(state) {
    return state.growthStagesByCropId;
  },
  growthStageIndex(state) {
    return state.growthStageIndex;
  },
  cropYieldsByCropIdAndSeason(state) {
    return state.cropYieldsByCropIdAndSeason;
  },
}

export interface CropsActions extends ActionMap {
  getCrops: (seasonId: string) => Promise<void>;
  getAllCrops: () => Promise<void>;
  refreshSeasonCrops: (seasonId: string) => Promise<void>;
  getCropGrowthStages: (cropId: string) => Promise<void>;
  fetchCropYields: (seasonId: string) => Promise<void>;
}

const actions: ActionTree<CropsState, CropsGetters, CropsActions> = {
  /**
    * Get all the available crops from the API and store them.
  */
  async getCrops(context, seasonId: string = null) {
    seasonId = seasonId || context.rootGetters.currentSeasonId;
    if (
      !context.rootGetters.currentFarmId ||
      !seasonId ||
      context.rootGetters.cropsPerSeason[seasonId]
    )
      return;

    const crops = await apiCall<Crop[]>(
      "GET",
      `/farm/${context.rootGetters.currentFarmId}/season/${seasonId}/crops/`
    );

    context.commit("setCrops", { crops, seasonId });

    await context.dispatch("fetchCropYields", seasonId);
  },
  async getAllCrops(context) {
    const response = await apiCall<Paginated<Crop>>("GET", "/crops/", { limit: 10000 });
    context.commit("setCrops", { crops: response.results, seasonId: "" });
  },
  async refreshSeasonCrops(context, seasonId: string = null) {
    seasonId = seasonId || context.rootGetters.currentSeasonId;
    context.commit("removeSeasonCrops", seasonId);
    await context.dispatch("getCrops", seasonId);
  },
  async getCropGrowthStages(context, cropId: string) {
    if (!context.state.growthStagesByCropId[cropId]) {
      const response = await apiCall<Crop>("GET", "/crops/" + cropId);
      const growthStages = response.growthstages;
      context.commit("setGrowthStagesByCropId", { cropId, growthStages });
      const stagesIndex = { ...context.state.growthStageIndex };
      growthStages.forEach((stage) => {
        stagesIndex[stage.id] = stage;
      });
      context.commit("setGrowthStageIndex", stagesIndex);
    }
  },
  async fetchCropYields(context, seasonId) {
    const cropYields = await apiCall<Paginated<CropYield>>(
      "GET",
      `/farm/${context.rootGetters.currentFarmId}/season/${seasonId}/cropyield/`,
      { limit: 10000 }
    );

    cropYields.results.forEach((cropYield) => {
      context.commit("setCropYieldsByCropIdAndSeason", {
        cropId: cropYield.crop,
        seasonId,
        cropYields: cropYield,
      });
    });
  },

}

const crops: Module<CropsState, CropsGetters, any> = {
  state: initialState(),
  mutations,
  actions,
  getters,
};

export default crops;
